<template>
  <div class="home">
     <!-- 顶部banner-->
    <template>
      <a-carousel autoplay>
          <Top-banner :img='img1' :title='title1' :text='text1'></Top-banner>  
          <Top-banner :img='img2' :title='title2' :text='text2'></Top-banner>  
      </a-carousel>
    </template>
  <!-- 华氪，解决您海外投放推广过程中的疑难杂症 -->
  <div class='questionContainer'>
    <div class='mainTitle'>华氪，解决您海外投放推广过程中的疑难杂症</div>
    <img src="../static/images/question.jpg" alt="">
    <div class='first'>华氪助您实现商业目标</div>
    <div class='second'>专业广告投放助您轻松获得精准全球客户，快速拓展海外目标市场</div>
    <a href="https://ifunpi.COM"><div class='more'>了解更多信息 > </div></a>
  </div>
<!-- 全媒体流量生态，覆盖国内优质流量资源 -->
  <div class='container1080-home'>
      <div style="margin-top:60px">
    <div class='mainTitle'>全媒体流量生态，覆盖国内优质流量资源</div>
    <div class='blockContainer-home'>
      <div class='block' v-for='(item,index) in blockList' :key="index">
        <img :src="item.img" alt="">
      </div>
    </div>
  </div>
  </div>
  <!-- 助你获得更多流量，实现商业目标 -->
  <div class='targetContainer'>
    <div class='mainTitle'>助你获得更多流量，实现商业目标</div>
    <div class='container1080-home'>
      <template>
      <a-carousel autoplay>
          <div class='targetBanner'  v-for='(item,index) in targetList' :key='index'>
            <img :src="item.img" alt="" style='width:450px;height:450px'>
            <div class='targetRight'>
              <div class='rightTitle'>  {{item.title}}</div>
              <div class='rightText'>
                {{item.text}}
              </div>
              <div class='count'>
                <div class='number'>{{item.num1}}</div>
                <div class='numberText'>{{item.numText1}}</div>
              </div>
              <div class='count'>
                <div class='number'>{{item.num2}}</div>
                <div class='numberText'>{{item.numText2}}</div>
              </div>
              <div class='count'>
                <div class='number'>{{item.num3}}</div>
                <div class='numberText'>{{item.numText3}}</div>
              </div>
               <div class='btn' style='margin-top:60px;margin-left:0px' @click='showModel'>投放广告</div>
            </div>
          </div>
      </a-carousel>
    </template>
    </div>
    
  </div>
  <!-- 助您获得全球流量，达成营销目标 -->
   <div class='container1080-home'>
    <div class='serverContainer'>
        <div class='mainTitle'>助您获得全球流量，达成营销目标</div>
          <template>
            <a-tabs type="card">
              <a-tab-pane key="1" tab="我们的优势">
              <div class='serverBlock'>
                <div class='everyblock' v-for='(item,index) in goodList' :key='index'>
                  <img :src="item.img" alt="">
                  <div class='title'>{{item.title}}</div>
                  <div class='text'>{{item.text}}</div>
                </div>
              </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="我们的服务">
              <div class='serverBlock'>
                <div class='everyblock' v-for='(item,index) in serverList' :key='index'>
                  <img :src="item.img" alt="">
                  <div class='title'>{{item.title}}</div>
                  <div class='text'>{{item.text}}</div>
                </div>
              </div>
              </a-tab-pane>
            </a-tabs>
          </template>
    </div>
  </div>
  <!-- 6个跳转链接 -->
  <div class="container">
       <div class='mainTitle' style='color:#ffffff'>课程学习</div>

          <div class="positionBlock container1080-6">
          <img src="../static/images/position.jpg" alt="">
          <div class='right6Block'>
            <div class='block6' v-for='(item,index) in blockList6' :key='index' :style="{'background-color':item.color}">
              <a :href="item.url">{{item.text}}</a>
            </div>
          </div>
        </div>
      
  </div>
  <my-model ref='myModel'></my-model>

</div>

</template>
<script>
// @ is an alias to /src 
import topBanner from '../components/topBanner';
import myModel from '../components/MyModel';


export default {
  name: 'Home',
  components: {
  TopBanner:topBanner,
  MyModel:myModel
  },
   data() {
    return {
      title1:'华氪网络科技有限公司',
      text1:'做一家靠谱、负责、口碑推荐的数字营销服务商',
      img1:require('../static/images/banner1.jpg'),
       title2:'海外流量运营推广社区',
      text2:'跨境知识分享+软件工具开发+靠谱服务推荐+行业资源对接',
      img2:require('../static/images/banner2.jpg'),
     blockList:[{img:require('../static/images/baidu.jpg')},{img:require('../static/images/360.jpg')},{img:require('../static/images/toutiao.jpg')},
     {img:require('../static/images/weibo.jpg')},{img:require('../static/images/youku.jpg')},{img:require('../static/images/tenxun.jpg')},
     {img:require('../static/images/qutoutiao.jpg')},{img:require('../static/images/sougou.jpg')}
     ],
     targetList:[{img:require('../static/images/target1.jpeg'),title:'深圳医疗清洁用品公司',text:'深圳医疗清洁用品公司成立于深圳，致力于开发和研究医疗用品各种清洁用品和清洁试剂，通过投放google和bing广告实现了超高ROI和自主品牌的树立',num1:'18次',numText1:'点击量',
     num2:'24次',numText2:'交易量',num3:'6倍',numText3:'投资回报率'},
     {img:require('../static/images/target2.jpeg'),title:'泉州服装贸易公司',text:'泉州服装贸易公司深耕服装市场多年，随着市场趋于饱和想由国内转向海外，苦于海外推广的壁垒我们为其选择了谷歌搜索进行推广，投入广告后的两个季度里面销量都超出其预期',num1:'23次',numText1:'点击量',
     num2:'32次',numText2:'交易量',num3:'7倍',numText3:'投资回报率'},
     {img:require('../static/images/target3.jpeg'),title:'广州女装独立站  ',text:'该公司在尝试过其他推广平台后处于亏损模式，处于他们独立站模式考虑我们为客户推荐Facebook广告，再投入广告后的一个季度里，单量持续上涨并在黑五购物季实现爆单',num1:'9次',numText1:'展示次数',
     num2:'9次',numText2:'交易次数',num3:'2人',numText3:'覆盖人数'}],

     goodList:[{img:require('../static/images/good1.png'),title:'渠道优势',text:'在搜索平台和社交媒体获得了众多全球巨头们的官方资格合作伙伴认证，用户覆盖率达全球80%。'},
     {img:require('../static/images/good2.png'),title:'定位准确',text:'强大的数据网络和经验丰富的账户投放经理，将您的广告精准投放至目标客户群。'},
     {img:require('../static/images/good3.png'),title:'全程跟踪服务',text:'从制定推广流程、开通账户到投放管理再到效果评估最后进行持续优化，您将享受到一站式服务。'},
     {img:require('../static/images/good4.png'),title:'渠道优高于市场的转化率势',text:'基于实时数据及爱竞的经验储备，制定推广计划，并根据数据的反馈进行调整直至优势最大化。'}],
     
     serverList:[{img:require('../static/images/server1.png'),title:'大数据分析',text:'在搜索基于海量用户信息，以各平台专业数据分析工具为依托，对您的数据规律进行可视化分析。'},
     {img:require('../static/images/server2.png'),title:'广告投放',text:'根据各平台中的不同用户群进行细分，将您的广告信息在对的时间、对的平台投放至对的人。'},
     {img:require('../static/images/server3.png'),title:'账户管理',text:'一站式账户管理服务，帮助您在目标平台快速开户，提供账号管理、后台操作指导等服务。'},
     {img:require('../static/images/server4.png'),title:'整合营销策略',text:'根据营销需求、目标受众，投放整合营销企划，为您量身定制最优营销方案，达成营销目标。'}],
   
      blockList6:[{color:'#fff',text:'资源对接',url:'https://ifunpi.com/career/communication/'},{color:'#eef4fa',text:'工作职场',url:'https://ifunpi.com/career/circle/'},{color:'#fff',text:'视频教程',url:'https://ifunpi.com/download/video-tutorial/'},
      {color:'#eef4fa',text:'文件资料',url:'https://ifunpi.com/download/file/'},{color:'#fff',text:'软件工具',url:'https://ifunpi.com/download/hammer/'},{color:'#eef4fa',text:'实操经验',url:'https://ifunpi.com/career/experience/'},]
  };
  },
    methods:{
    showModel(){
      this.$refs.myModel.visible = true;
    }
  }
}
</script>

<style>
 .container1080-home{
  width: 1080px!important;
  margin: auto;
}
  .mainTitle{ 
    font-size: 30px;
    color: #222;
    line-height: 46px;
    text-align: center;
    font-weight: bold;
    margin:30px 0;  
  }
  .mainTitle:after{
    display: block;
    content: "";
    width: 35px;
    height: 4px;
    margin: 16px auto 12px;
    border-radius: 2px;
    background-image: linear-gradient(90deg,#4bf5ff,#2f6aff);
  }
  .questionContainer{
    text-align: center;
  }
  .first{
    font-size: 16px;
    color: #393939;
    letter-spacing: 1px;
  }
  .second{
   font-size: 18px;
    color: #2b2b2b;
    margin-bottom: 5px;
  }
  .more{
      font-size: 16px;
    color: #2f6aff;
  }
  .blockContainer-home{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px;
  }
  .block{
    width: 23%;
    height: 120px;
    cursor: pointer;
    margin-bottom: 40px;
    background: #fff;
    position: relative;
    border: 1px solid #d8dfe4;
  }
  .block img{
    width: 170px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .targetContainer{
   height: 720px;
    position: relative;
    background-color: #f7f9fb;
    padding:40px 0;
  }
  .targetBanner{
    width: 95%!important;
    overflow:hidden;
    background-color: #ffffff;


  }
  .targetBanner img{
   width: 45%;
    height: 100%;
    float: left;
    margin-right: 4%;
    display: block;
  }
  .targetRight{
    width: 48%;
    padding:50px 0;
    text-align: left;
    float: left;
  }
  .rightTitle{
    color: #1c3366;
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .rightText{
    font-size: 16px;
    color: #1f2937;
    letter-spacing: 0;
    line-height: 28px;
    opacity: .8;
    width: 98%;
  }
  .number{
    font-size: 22px;
    color: #3e76ff;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .numberText{
    font-size: 14px;
  }
  .count{
    margin-top: 30px;
    display: inline-block;
    margin-right: 80px;
  }

 .targetContainer .ant-carousel .slick-dots li.slick-active button{
   width: 28px!important;
    height: 8px!important;
    border-radius: 12px!important;
    margin-right: 15px!important;
    margin-top: 40px!important;
    background-image: linear-gradient(334deg,#772fff,#2f6aff 30%,#4bf5ff)!important;

}
 .targetContainer .ant-carousel .slick-dots li button{
  width: 14px!important;
    height: 8px!important;
    border-radius: 12px!important;
    margin-right: 15px!important;
    margin-top: 40px!important;
   background-color: #c0c4cc !important;
}
.serverBlock{
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.everyblock{
width: 20%;
text-align: center;
}
.everyblock img{
  width: 130px;
}

.everyblock .title{
  color: #181818;
    font-weight: 400;
    font-size: 18px;
}

.everyblock .text{
  color: #999;
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
}

   .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    color: #333 !important;
    border: 1px solid #e8e8e8 !important;
    cursor: pointer !important;
    font-size: 14px !important;
    border-radius: 0 30px 0 30px !important;
}
  .ant-tabs-tab-active{
    color: #fff !important;
    background: linear-gradient(90deg,#4bf5ff -100%,#2f6aff 120%) !important ; 
}

.container{
    background-image:url('../static/images/6bg.jpg');
    background-size:cover;
    height:480px;
    margin-top: 40px;
    padding-top:30px;
    margin-bottom: 110px;
}
.positionBlock{
  height:430px;
}
.positionBlock img{
width: 35%;
display: block;
float:left;
height:100%
}
.right6Block{
width: 65%;
height: 100%;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
  background-color: #fff;

}
.right6Block .block6{
  width:33%;
  height:215px;
}
.container1080-6{
  width: 1080px;
  margin:0 auto;
}
.block6 a{
  display:block;
  margin-top: 46%;
  font-size: 20px;
  font-weight: bold;
}


</style>
