<template>
  <div class='main'>
      <div class='container1080-footer'>
        <div class='footerContainer'>
          <div class='logContainer footerBlock'>HUAKE</div>
          <div class='footerBlock'>
            <div class='footerTitle'>产品链接</div>
            <div class='footerText' @click='toGoogle'>国外广告</div>
            <div class='footerText' @click='toBaidu'>国内广告</div>
            <div class='footerText' @click='toAbout'>关于我们</div>
          </div>
          <div class='footerBlock'>
            <div class='footerTitle'>华氪学院</div>
            <a href="https://ifunpi.com/forums/"><div class='footerText'>华氪学院</div></a> 
          </div>
          <div class='footerBlock'>
            <div class='footerTitle'>联系我们</div>
            <div class='footerText'>客服热线（8:30-21:00）</div>
            <div class='footerTitle'>400-6666-3288</div>
            <div class='footerText'>浙江省杭州市钱塘新区下沙街道艾迪科创园 2幢 321</div>
          </div>
          <div class='footerBlock'>
            <div class='footerTitle'>关注我们</div>
            <img src="../static/images/qr.png" alt="" class='qr'>
            <div class='footerText'>华氪微信公众号</div>
          </div>
        </div>
        <div class='footer-address'>© 2021 浙江华氪网络科技有限公司  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://beian.miit.gov.cn/"> 浙ICP备2021017547号-1 </a>  </div>
      </div>
  </div>
</template>

<script>
export default {
 name: 'Footer',
 components:{
   
 },
  data() {
    return {
     
    };
  },
   methods: {
  toGoogle(){
     this.$router.push({ path:'/google'})
    },
   toBaidu(){
     this.$router.push({ path:'/baidu'})
   },
   toAbout(){
     this.$router.push({ path:'/about'})
   },

 }
};
</script>

<style>
.main{
    bottom: 0;
    width: 100%;
    color: #fff;
    font-size: 14px;
    padding-top: 50px;
    background: #0a1e39;
}
.container1080-footer{
  width: 1080px;
  margin: auto;
}
.footerContainer{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footerBlock{
    width: 20%;
    text-align: left;
    font-size: 35px;
    font-weight: 600;
    margin-right: 20px;
}
.footerTitle{
  font-size: 16px;
    font-weight: 600;
    color: #fff;
    line-height: 24px;
    margin-bottom: 16px;
}
.footerText{
  font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 20px;
    margin-bottom: 13px;
     cursor:pointer;
    
}
.footer-address{
    text-align: center;
    padding: 30px 0;
    font-size: 12px;
    color: #666d76;
}
.qr{
  width:100px;
  height: 100px;
  margin-bottom: 10px;
}
  .logContainer{
  font-style: italic;
  }  

</style>